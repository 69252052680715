import axios from "axios";
import store from "../store";
import router from "../router";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export const requestApi = async (pathApi, postData = Object(), formData = null) =>
  axios({
    url: VUE_APP_API_URL + pathApi,
    method: Object["keys"](postData)["length"] || formData ? "POST" : "GET",
    data: postData,
    headers: {
      "Content-Type": formData ? "multipart/form-data" : "application/json;charset=utf-8",
      Authorization: "Bearer " + store.getters.token,
    },
    timeout: 60000,
  })
    .then((result) => {
      if (result.data.status === 401 && router.history.current.fullPath !== "/login") {
        // router.push('/login');
      }
      return result.data;
    })
    .catch((error) => {
      if (!error.response) {
        // router.push('/503')
        // window.toastr.error('Bạn đã thực hiện thao tác quá nhanh, vui lòng thử lại!', 'Thông Báo') // Thông báo của 502
      }
      if (error.response) {
        return error.response.data;
      }
    });
