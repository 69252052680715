<template>
    <div class="page-content">
        <div class="container-fluid">
            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">System Config</h4>
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript: void(0);">Admin Traodoicheo</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    System Config
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <loading-vue v-if="loading" />

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Table Border color</h4>
                            <p class="card-title-desc">Add <code>.table-bordered</code> &amp; <code>.border-*</code> for
                                colored borders on all sides of the table and cells.</p>

                            <div class="table-responsive">
                                <table class="table table-bordered border-primary mb-0">

                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th scope="col">Alias</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">Thao Tác</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="config in configs" :key="config.id" class="">
                                            <td scope="row">{{ config.id }}</td>
                                            <td>{{ config.alias }}</td>
                                            <td>{{ config.description }}</td>
                                            <td><input type="text" disabled class="form-control"
                                                    v-model="config.value" /></td>
                                            <td><button class="btn btn-primary px-3" data-bs-toggle="modal"
                                                    data-bs-target="#modalEditConfig" @click="setConfigEditing(config)">
                                                    Sửa
                                                </button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div><!--end col-->
            </div>

        </div>

        <div class="modal fade" id="modalEditConfig" tabindex="-1" aria-hidden="true" ref="container">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Sửa Config <span class="text-danger">{{ configEditing.alias }}</span>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="fw-bold text-start mb-3">
                            <div class="mb-3">
                                <label for="" class="form-label">Value: </label>
                                <textarea class="form-control" v-model="configEditing.value" rows="10"></textarea>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-success py-1 w-50" @click="saveConfig(configEditing)">Lưu</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
import systemConfigApi from "@/api/system-config";
import LoadingVue from "@/components/loading/Loading.vue";

export default {
    name: "SystemConfig",
    components: {
        LoadingVue,
    },
    data() {
        return {
            loading: false,
            configs: {},
            configEditing: { alias: "", value: "" },
        };
    },
    created() {
        this.getConfig();
    },
    methods: {
        setConfigEditing(config) {
            this.configEditing = config;
            console.log(this.configEditing);
        },
        async getConfig() {
            this.loading = true;
            const response = await systemConfigApi.list();
            console.log(response);
            this.loading = false;
            if (response?.success) {
                this.configs = response.data.map(x => {
                    if (typeof x.value === "object") {
                        x.value = JSON.stringify(x.value, null, 4);
                    }
                    return x;
                })
            } else {
                this.$toastr.error(
                    response?.message || "Không load được config !"
                );
            }
        },
        async saveConfig(config) {
            const { alias, value } = config;
            const params = {
                alias,
                value,
            };
            const response = await systemConfigApi.update(params);
            if (response?.success) {
                this.$swal("Thành công", response?.message || "Thành công !", "success");
                document.documentElement.querySelector(".modal.fade.show .btn-close").click();
            } else {
                this.$swal("Lỗi", response?.message || "Thất bại !", "error");
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
