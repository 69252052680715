<template>
  <div class="text-center">
    <img :src="Loading" alt="loading" width="35" height="35" />
  </div>
</template>

<script>
import Images from "@/config/images";

export default {
  name: "Loading",
  data() {
    return {
      Loading: Images.Loading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
